import { micro_services } from './environment';

export function load_micro_service_changelog(url, serviceName) {
  const divId = `changelog-${serviceName}`;
  const containerId = `releasenotes-${serviceName}`;

  fetch(url)
    .then(response => response.text())
    .then(html => {
      // in case changelog is not found, index.html will return
      // and this cause infinite loop
      // so we check if the correct changelog is returned
      if (html.includes(containerId)) {
        $(`#${divId}`).html(html);
      }
    })
    .catch(error => {
      console.log(error);
    });
}

export function load_micro_service_changelogs() {
  for (const [key, value] of Object.entries(micro_services)) {
    $('#ms-changelog-container').append(`<div class="container" id="changelog-${key}" />`);
    $('#ms-releasenotes-container').append(
      `<li><a class="dropdown-item" href="#releasenotes-${key}" data-bs-toggle="modal">${key}</a></li>`
    );
    load_micro_service_changelog(value.url, key);
  }
}
